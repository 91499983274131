
import React, { Component } from "react";
import react,{ useState } from "react"
import { Link, Redirect } from 'react-router-dom';
import TextField from '@material-ui/core/TextField';
import { withStyles } from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import MenuItem from '@material-ui/core/MenuItem';
import Axios from "axios";
import MuiAlert from "@material-ui/lab/Alert";
import { Bold } from "react-feather";
import helpdesk from '../helpdesk.png'


export default function ReportConfirmation(){

    const agentNumber = localStorage.getItem("AgentNumber")
    const IssueType = localStorage.getItem("issueType")
    const [initialBlock,setinitialBlock]=useState(true)
    let str=''
    function Submitform(){
        
        const axios = require('axios');
        const qs = require('qs');
        let api='https://ekyc.chili.mu:9443/api/sim/lost/damage/detail'

         let data = qs.stringify({
                    'msisdn': "230"+localStorage.getItem('lostMSISDN'),
                    'id64': localStorage.getItem('imgId13'),
                    'simCase': localStorage.getItem('issueType'),
                    'doc64': localStorage.getItem('imgId12')
                });
            
            
            let config = {
                method: 'post',
                maxBodyLength: Infinity,
                url: api,
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded'
                },
                data: data
            };
            axios.request(config)
            .then((response) => {
                console.log(JSON.stringify(response.data));
                str = response.data.data
                str = str.split(" ")
               let token = str[3]
               localStorage.setItem("ComplaintNumber",token)
                console.log(token)
                setinitialBlock(false)

            })
            .catch((error) => {
                // console.log(error)
               alert("Details for this MSISDN already there")
               window.location.replace('/logout')

            });



    }
 
    return(
        <div className="Auth-container" style={{textAlign:'center'}}>
{
                    agentNumber==null || agentNumber.trim().length==0?
                    null
                    :<div  style={{marginTop:-50,textAlign:'center'}}>
                        <label><strong>Agent Number:{agentNumber}</strong></label>
                        <Link to="/logout" style={{textAlign:'end',marginLeft:20,color:'black'}}>Logout</Link>
                        <br></br>
                    </div>
                }

                {initialBlock == true?
                <div>
                    <h5 style={{color:'#00BFFF',textDecoration: 'underline'}}>Complaint Registration Confirmation Form </h5>

                        <br></br>
                        <div style={{textAlign:'start'}}>
                            <label style={{color:'#00BFFF'}}>MSISDN:</label> <label>{'230'+localStorage.getItem('lostMSISDN')}</label>
                            <br></br>
                            <br></br>
                            <h6 style={{color:'#00BFFF',textDecoration: 'underline', textAlign:'center'}}>Documents Submitted </h6>
                            <br></br>
                            <br></br>
                            {IssueType == 'damaged'?
                                <label style={{color:'#00BFFF',textDecoration: 'underline'}}>Application with Damaged SIM</label>
                                :<label style={{color:'#00BFFF',textDecoration: 'underline'}}>Police Memo</label>
                            }
                            <br></br>
                            <br></br>
                            <img
                                className="classid_f_succ"
                                src={`data:image/jpeg;base64,${localStorage.getItem('imgId12')}`}
                                alt="classid_f_succ"
                                style={{ marginLeft: -120, marginTop: 0, marginLeft: 20 }}
                            />
                            <br></br>
                            
                            <br></br><br></br>
                            <br></br><br></br>
                            <br></br><br></br>
                            <br></br><br></br>
                            <br></br>
                            <label style={{color:'#00BFFF',textDecoration: 'underline'}}>Idproof</label>
                            <br></br>
                            <br></br>
                            <img
                                className="classid_f_succ"
                                src={`data:image/jpeg;base64,${localStorage.getItem('imgId13')}`}
                                alt="classid_f_succ"
                                style={{ marginLeft: -120, marginTop: 0, marginLeft: 20 }}
                            />
                            <br></br><br></br>
                            <br></br><br></br>
                            <br></br><br></br>
                            <br></br><br></br>
                            <div style={{textAlign:'center'}}>
                                <button className="button" style={{ fontSize: 16,width: 150, padding:7}}
                                onClick={()=>{Submitform()}}>Submit</button>
                            </div>
                        </div>
                </div>
                        :
                        <div>
                            <img
                                className=""
                                src={helpdesk}
                                style={{
                                    height: 180,
                                    marginTop: 100,
                                }}
                        alt="logoo"></img>
                        <br></br>
                        <br></br>
                        <br></br>
                        <h4> Customer's Complaint has been saved </h4>
                        <label>Reference Number is- <strong>{localStorage.getItem("ComplaintNumber")}</strong></label>
                        <br></br>
                        <br></br>
                        <button   className="button_id"style={{ fontSize: 16,width: 120, padding:10}}>
                                 <Link className="link1" to="/choosecustomer"> Login Again?  </Link>
                       </button>
                       <br></br>
                       <br></br>
                        <button   className="button_id"style={{ fontSize: 16,width: 120, padding:10}}>
                        <Link className="link1" onClick={()=>{//sendEmail()
                                    }} to="/logout"> Exit  </Link>
                        </button>
                            </div>}

                
        </div>
    )
}