import React, { Component, useState } from 'react';
import { Link, Redirect } from 'react-router-dom';
import TextField from '@material-ui/core/TextField';
import { withStyles } from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import Moment from 'moment';
import SignatureCanvas from "react-signature-canvas";
import MuiAlert from "@material-ui/lab/Alert";
import Axios from "axios";

import {
    KeyboardDatePicker,
    MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import PropTypes from 'prop-types';
import './style1.css';
import axios from 'axios';
import './style.scss';
import user from '../user.svg';
import bank from '../mansabank.png';
import { Input } from '@material-ui/core';
import { Label } from 'reactstrap';
import Bottomstrip from './Bottomstrip';
import Autocomplete, { createFilterOptions } from '@material-ui/lab/Autocomplete';




const format = /[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~0123456789]/;
const useStyles = (theme) => ({
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },

    cssLabel: {
        color: '#00BFFF',
    },
    input: {
        color: '#00BFFF',
    },

    cssOutlinedInput: {
        '&$cssFocused $notchedOutline': {
            borderColor: `${theme.palette.primary.main} !important`,
        },
    },

    cssFocused: {},

    notchedOutline: {
        borderWidth: '1px',
        borderColor: 'Gray !important',
    },
    select: {
        '&:before': {
            borderColor: 'Gray',
        },
        color: '#00BFFF',
    },
    icon: {
        fill: 'Gray',
    },
});



class Auth extends Component {



    constructor(props) {
        super(props);
       
        this.state = {
            
            uservalue: localStorage.getItem("userType"),
            IDTYPE: localStorage.getItem('IDtype'),
            json_len: -1,
            
            cnt: 1,
            cli: 1,
            details: {
                fn: '',   //localStorage.getItem('fn'),
                ln: '',    //localStorage.getItem('ln'),
               
                puid: '',
                uid: ''

                //address: localStorage.getItem('address'),
                //accountType: 'Savings Account',
                //occupation: '',
                //mother: '',
                //place: '',  

            },
        };
        this.hancli = this.hancli.bind(this);
        this.handleChange = this.handleChange.bind(this);
    }



    componentWillMount() {

        // this.setState({ date: date1 });
    }
    onDateChange = (date) => {
        this.setState((prevState) => ({
            details: {
                ...prevState.details,
                date: date
            },
        })
        );
      

    }




    componentDidUpdate() {

        //localStorage.setItem('data', JSON.stringify(this.state));
        //localStorage.setItem('date1', '' + this.state.details.date.getTime());
        //console.log(this.state.details.date);
    }
    mySubmitHandler = (event) => {
        event.preventDefault();
        let nam = this.state.details.fn;
        let nam2 = this.state.details.ln;
        let permit = this.state.details.puid;
        let uid = this.state.details.uid;
        // let age = calculate_age(this.state.details.date);
        let cnt = this.state.cnt;

        localStorage.setItem('fn', nam);
        localStorage.setItem('ln',nam2);
        localStorage.setItem('pmUid', permit);
        localStorage.setItem('uid', uid);

    };
    hancli() {
        let cli = this.state.cli;
        cli = cli + 1;
        this.setState({ cli: cli });
    }
    handleChange(event) {
        let nam = event.target.name;
        let val = event.target.value;
        this.setState((prevState) => ({
            details: {
                ...prevState.details,
                [nam]: val,
            },
        }));
        console.log('cc', val);
        if (nam == "firstName") {
            localStorage.setItem('fn', val)
        }
        if (nam == "lastName") {
            localStorage.setItem('ln', val)
        }
       
        if (nam == "puid") {
            localStorage.setItem('puid', val)
        }
        if (nam == "uid") {
            localStorage.setItem('uid', val)
        }

        console.log(nam, val);

        // this.setState(prevState=>{
        //     let details = Object.assign({}, prevState.details);
        //     nam = val;
        // })

    };
    render() {
        // console.log(this.state.age);
        if (this.state.loggedin === false) {
            return <Redirect to="/"></Redirect>;
        }
        const { classes } = this.props;
        return (
            <React.Fragment>
               
                <div className="Auth-container" style={{ textAlign: 'center' }}>
                   
                    <h4 style={{ color: '#00BFFF' }}>

                        {
                            this.state.agentNumber == null || this.state.agentNumber.trim().length == 0 ?
                                <div>Please fill Your information </div>
                                : <div>Please fill Customer UID information</div>
                        }
                    </h4>

                    <form onSubmit={this.mySubmitHandler}>

                        <br></br>

                        <div
                            style={{
                                textAlign: 'left',
                                color: 'blue',
                                marginLeft: -5,
                                marginTop: -10,
                                marginBottom: 10,
                            }}
                        >
                            <h6 style={{ backgroundColor: 'gray', color: 'white' }}>
                                UID DETAILS
                            </h6>
                        </div>
                       

                        
                            <TextField
                                // required
                                name="fn"
                                id="outlined-required"
                                label={<div style={{ color: 'black' }}>First Name</div>}
                                InputLabelProps={{
                                    classes: {
                                        root: classes.cssLabel,
                                        focused: classes.cssFocused,
                                    },
                                }}
                                InputProps={{
                                    classes: {
                                        input: classes.input,
                                        root: classes.cssOutlinedInput,
                                        focused: classes.cssFocused,
                                        notchedOutline: classes.notchedOutline,
                                    },
                                }}
                                variant="outlined"
                                onChange={(e) => this.handleChange(e)}
                                value={this.state.details.fn}
                            />
                        

                        <br></br>
                        <br></br>
                        <TextField
                            // required
                            name="ln"
                            id="outlined-required"
                            label={<div style={{ color: 'black' }}>Last Name</div>}
                            InputLabelProps={{
                                classes: {
                                    root: classes.cssLabel,
                                    focused: classes.cssFocused,
                                },
                            }}
                            InputProps={{
                                classes: {
                                    input: classes.input,
                                    root: classes.cssOutlinedInput,
                                    focused: classes.cssFocused,
                                    notchedOutline: classes.notchedOutline,
                                },
                            }}
                            variant="outlined"
                            onChange={(e) => this.handleChange(e)}
                            value={this.state.details.ln}
                        />


                        {localStorage.getItem("userType") == "resident" &&(

                            <div>
                                <br></br>
                                <br></br>
                                            <TextField
                                                // required
                                                name="puid"
                                                id="outlined-required"
                                                label={<div style={{ color: 'black' }}>Permit-ID</div>}
                                                InputLabelProps={{
                                                    classes: {
                                                        root: classes.cssLabel,
                                                        focused: classes.cssFocused,
                                                    },
                                                }}
                                                InputProps={{
                                                    classes: {
                                                        input: classes.input,
                                                        root: classes.cssOutlinedInput,
                                                        focused: classes.cssFocused,
                                                        notchedOutline: classes.notchedOutline,
                                                    },
                                                }}
                                                variant="outlined"
                                                onChange={(e) => this.handleChange(e)}
                                                value={this.state.details.puid}
                                            />
                                
                        <br></br>
                        <br></br>

                        <TextField
                                        // required
                                        name="uid"
                                        id="outlined-required"
                                        label={<div style={{ color: 'black' }}>UID</div>}
                                        InputLabelProps={{
                                            classes: {
                                                root: classes.cssLabel,
                                                focused: classes.cssFocused,
                                            },
                                        }}
                                        InputProps={{
                                            classes: {
                                                input: classes.input,
                                                root: classes.cssOutlinedInput,
                                                focused: classes.cssFocused,
                                                notchedOutline: classes.notchedOutline,
                                            },
                                        }}
                                        variant="outlined"
                                        onChange={(e) => this.handleChange(e)}
                                        value={this.state.details.uid}
                                    />

                            </div>
                        )}
                          

                        <br></br>
                        <br></br>

                       

                        
                <button
                        className="button"
                        onClick={this.mySubmitHandler}
                        style={{
                            marginLeft: 0,
                            width: 150,
                            textAlign: 'center',
                            fontSize: 15,
                            marginTop: 100
                        }}
                        >
                       
                        <Link to="/selfie"  className="link1">
                           Proceed
                        </Link> 

                        </button>

                    </form>

                </div>


            </React.Fragment>
        );
    }
}
Auth.propTypes = {
    classes: PropTypes.object.isRequired,
};
export default withStyles(useStyles)(Auth);