import React, { Component, useState } from 'react';
import { Link, Redirect } from 'react-router-dom';

import TextField from '@material-ui/core/TextField';
import { withStyles } from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import ImageList from '@material-ui/core/ImageList'
// import SignatureCanvas from "react-signature-canvas";
import Moment from 'moment';

import {
    KeyboardDatePicker,
    MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import PropTypes from 'prop-types';
import './style1.css';
import axios from 'axios';
import './style.scss';
import user from '../user.svg';
import bank from '../mansabank.png';
import { Input } from '@material-ui/core';
import { Label } from 'reactstrap';

export default function displayDetails(){


    function submitdetails(){
        window.location.replace('/selfie')
    }

    return(
            <div className = "Auth-container" style={{textAlign:'center'}}>
               <h4 style={{ color: '#00BFFF' }}> Preview Customer Details </h4>
               <div
                            style={{
                                textAlign: 'left',
                                color: 'blue',
                                marginLeft: -5,
                                marginTop: 10,
                                marginBottom: 5,
                            }}
                        >
                            <h6 style={{ backgroundColor: '#00BFFF', color: 'white' }}>
                                Customer Details
                            </h6>
                        </div>
                        <TextField
                            // required
                            name="fname"
                            style={{marginBottom:5}}
                            id="outlined-required"
                            label={<div style={{color:'black'}}>First Name</div>}
                            variant="outlined"
                            value={localStorage.getItem('fn')}/>
                            <br></br>
                            <TextField
                            // required
                            name="lname"
                            style={{marginBottom:5}}
                            id="outlined-required"
                            label={<div style={{color:'black'}}>Last Name</div>}
                            variant="outlined"
                            value={localStorage.getItem('ln')}/>

                            <br></br>
                            <TextField
                            // required
                            name="cc"
                            style={{marginBottom:5}}
                            id="outlined-required"
                            label={<div style={{color:'black'}}>Nationality</div>}
                            variant="outlined"
                            value={localStorage.getItem('countryCode')}/>

                             <br></br>
                            <TextField
                            // required
                            name="ID Number"
                            style={{marginBottom:5}}
                            id="outlined-required"
                            label={<div style={{color:'black'}}>ID Number</div>}
                            variant="outlined"
                            value={localStorage.getItem('id')}/>
                             <br></br>
                            <TextField
                            // required
                            name="dob"
                            style={{marginBottom:5}}
                            id="outlined-required"
                            label={<div style={{color:'black'}}>Date of Birth,Place</div>}
                            variant="outlined"
                            value={localStorage.getItem('dob')+","+localStorage.getItem('birthplace')}/>

                             <br></br>
                            <TextField
                            // required
                            name="profession"
                            style={{marginBottom:5}}
                            id="outlined-required"
                            label={<div style={{color:'black'}}>Profession</div>}
                            variant="outlined"
                            value={localStorage.getItem('profession')}/>
                             <br></br>
                            <TextField
                            // required
                            name="gender"
                            style={{marginBottom:5}}
                            id="outlined-required"
                            label={<div style={{color:'black'}}>Gender</div>}
                            variant="outlined"
                            value={localStorage.getItem('gender')}/>
                             <br></br>
                            <TextField
                            // required
                            name="marital_status"
                            style={{marginBottom:5}}
                            id="outlined-required"
                            label={<div style={{color:'black'}}>Marital Status</div>}
                            variant="outlined"
                            value={localStorage.getItem('marital_status')}/>
                            <br></br>
                            <button className="button" 
                                    style={{ marginLeft: 0,width: 150,textAlign: 'center',fontSize: 15}}
                                    onClick={()=>submitdetails()}>
                                Save and Continue</button>
                                <br></br>
                            <button className="button" 
                                    style={{ marginLeft: 0,width: 150,textAlign: 'center',fontSize: 15}}
                                    onClick={()=>window.location.replace('/validate')}>
                                Back</button>
                        
            </div>
    )

}