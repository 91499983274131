import react,{ useState } from "react"
import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';
import TextField from '@material-ui/core/TextField';
import { withStyles } from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import MenuItem from '@material-ui/core/MenuItem';
import Bottomstrip from "./Bottomstrip";
import './custome.css'
import Axios from "axios";
import thankyou from '../thankyou.png'
import MuiAlert from "@material-ui/lab/Alert";

export default function ChooseCustomer(){
const[initialBlock,setinitialBlock]=useState("initial")
const [msisdn,Setmsisdn]=useState("")
localStorage.removeItem('data');
        localStorage.removeItem('imgData');
        localStorage.removeItem('imgId1');
        localStorage.removeItem('imgId2');
        localStorage.removeItem('date1');
        localStorage.removeItem('whichid');
        localStorage.removeItem('dob');
        localStorage.removeItem('imgId7');
        localStorage.removeItem('imgId2');
        localStorage.removeItem('imgId8');
        localStorage.removeItem('imgData');
        localStorage.removeItem('imgData');
        localStorage.removeItem('doc_f');
        localStorage.removeItem('address');
        localStorage.removeItem('whichdoc');
        localStorage.removeItem('adressdocumentType');
        localStorage.removeItem('from');
        localStorage.removeItem('id_f');
        localStorage.removeItem('kyc-data');
        localStorage.removeItem('fn');
        localStorage.removeItem('ln');
        localStorage.removeItem('dob');
        localStorage.removeItem('gender');
        localStorage.removeItem('countryCode');
        localStorage.removeItem('id');
        localStorage.removeItem('whichid');
        localStorage.removeItem('IDtype');
        localStorage.removeItem('correlationId');
        localStorage.removeItem('mn');
        localStorage.removeItem('dateOfBirth');
        localStorage.removeItem('pmUid');
        localStorage.removeItem('photograph');
        localStorage.removeItem('icta-process');
        localStorage.removeItem('data');
        localStorage.removeItem('whichdoc');
        localStorage.removeItem('kyc-photo');
        localStorage.removeItem('KYCresult');
        localStorage.removeItem('SelfieCompleted');
        localStorage.removeItem('selfieUpdateStatus');
        localStorage.removeItem('SelfieCompleted');
        localStorage.removeItem('oksubmit');
        localStorage.removeItem('email');
        localStorage.removeItem('type');
        localStorage.removeItem('puid');
        localStorage.removeItem('IconType');
        localStorage.removeItem('contingency')
        localStorage.removeItem('adressdocumentType');
        localStorage.removeItem('Token');
        localStorage.removeItem('showForm');
        localStorage.removeItem('issueType');
        localStorage.removeItem('imgId12');
        localStorage.removeItem('imgId13');
        localStorage.removeItem('lostMSISDN');
        localStorage.removeItem('from');
        localStorage.removeItem('ComplaintNumber')


       function gotoSIMReplacement(){
            window.location.replace('/Simreplacement')
        }




    return(
        <div className="Auth-container" style={{textAlign:'center'}}>
            { 
                initialBlock=="initial"?
                <div>
                    <h5 style={{ color: '#00BFFF' }}>Customer Type Selection </h5>
                    <h6 style={{ color: 'Gray' }}> Please Select Customer Type </h6>
                    <br></br>
                    <br></br>
                    <br></br>
                    {localStorage.getItem('agentType')=='Agent'?
                      <div>
                        <Link to="/customerlogin"> <button className="button_id" style={{fontSize: 16,width: 250, padding:10}}
                            onClick={()=>{
                        // setinitialBlock('Existing')
                                    localStorage.setItem("NewUser","no")}}>
                                    Existing Customer </button></Link>
                            <br></br>

                            <br></br>
                    <button className="button_id" style={{fontSize: 16,width: 250, padding:10}} 
                            onClick={()=>{ setinitialBlock('New')

                                            localStorage.setItem("NewUser","yes")}}>
                                            New Customer </button>
                                            <br></br>

                            <br></br>
                    <button className="button_id" style={{fontSize: 16,width: 250, padding:10}} 
                            onClick={()=>{ gotoSIMReplacement()}}>
                                            SIM Replacement </button>
                </div>
                    :localStorage.getItem('agentType')=='dealer' || localStorage.getItem('agentType')=='Dealer' ?
                    <button className="button_id" style={{fontSize: 16,width: 250, padding:10}} 
                    onClick={()=>{ setinitialBlock('New')

                                    localStorage.setItem("NewUser","yes")}}>
                                    New Customer </button>
                    :null}
                    
                </div>

// Existing Customer Block Starts here
                :initialBlock=="Existing"?
                            <div>
                                <button className="button_id" style={{fontSize: 16,width: 250, padding:10}} onClick={()=>{setinitialBlock("initial")}}>back</button>
                            </div>


// New CustomerBlock starts here
                :initialBlock=="New"?
                        <div>
                            <h5 style={{ color: '#00BFFF' }}>New Customer </h5>
                            <h6 style={{ color: 'Gray' }}> Please Enter new CHili Number you want to activate (* For Tourists please issue SIM with 702 series Only) </h6>
                            <TextField
                            name="msisdn"
                            type="tel"
                            id="outlined-required"
                            label={<div style={{color:'black'}}>Number</div>}
                            variant="outlined"
                            inputProps={{maxLength:8}}
                            onChange={(e)=>{Setmsisdn(e.target.value)
                            localStorage.setItem("CustNumber",e.target.value)}}
                            />
                            <br></br>
                            <br></br>
                            { msisdn.trim().length == 8  ?
                                <button className="button_id"style={{ fontSize: 16,width: 120, padding:10}}
                                onClick={()=>{
                                    // sendSmsToAlternativeNumber('new');
                                        window.location.replace("/categories")
                                }}> Submit</button>
                                :null
                            }
                               

                        <button className="button_id" style={{fontSize: 16,width: 120, padding:10}} onClick={()=>{setinitialBlock("initial")}}>Back</button>


                        </div>
                :null
            }
            

        </div>
    )
}