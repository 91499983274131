import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import './style1.css';
import Bottomstrip from './Bottomstrip';
import SignatureCanvas from "react-signature-canvas";
import Axios from 'axios';
import mansa from '../mansa.jpeg'
import afgbank from '../afgbank.jpeg'
import bicici from '../bicici.jpeg'
import Checkbox from '@mui/material/Checkbox';
import TextField from '@material-ui/core/TextField';

const agentNumber = localStorage.getItem('agentNumber');
const label = { inputProps: { 'aria-label': 'Checkbox demo' } };



// function Alert(props) {
//     return <MuiAlert elevation={6}
//         variant="filled" {...props} />;
// }





 

class Success extends Component {
    
    constructor(props) {
        super(props);
        const Selfie = localStorage.getItem('imgData');
        const Data = localStorage.getItem('data');
        const id_f = localStorage.getItem('imgId1');
        const id_b = localStorage.getItem('imgId2');
        const Data1 = JSON.parse(Data);
        let tokenStr=localStorage.getItem('Token')

        if(localStorage.getItem('contingency')!=null &&localStorage.getItem('contingency')=='true' ){
            tokenStr="T"+tokenStr;
        }
        localStorage.setItem('IconType','check');

        const whichid = localStorage.getItem('whichid');
        // this.state = {
        //     isComplete: Data1 && Selfie ? true : false,
        //     Selfie: Selfie,
        //     Data: Data1,
        //     id_f: id_f,
        //     id_b: id_b,
        // };
        this.state = {
         UserType : localStorage.getItem("userType"),
         agentNumber : localStorage.getItem("AgentNumber"),
         token:tokenStr,
         customernumber:localStorage.getItem('CustomerNumber'), 
         mailid:localStorage.getItem('email') ,
         addressprooftype:localStorage.getItem("adressdocumentType") ,
         newCustomer:localStorage.getItem("NewUser")
        }
    }
     pushAgentDetails(){
        let tokenStr=this.state.token;
        let uid = localStorage.getItem('uid');
        let kyc_status = 'Verified';
        if(uid!=null && localStorage.getItem('contingency')){
            kyc_status = 'Contingency';
        }
        var today = new Date(),
         date = today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate()+' '+today.getHours()+':'+today.getMinutes()+':'+today.getSeconds();

        const json = JSON.stringify({ "token": this.state.token, "agentnumber": "230"+this.state.agentNumber, "date": date, "kyc_status": kyc_status, "activation_status": "Pending" });
        console.log(json);
        const request = Axios.post('https://ekyc.chili.mu:9443/api/agentActivation', json, {
            headers: {
                "Content-Type": "application/json; charset=utf-8",
                "Access-Control-Allow-Headers": "*",
                "Access-Control-Allow-Origin": "*",
                "Access-Control-Allow-Methods": "*"
            }
        }).then((res)=>{
            console.log("Agent Activation response  ",res);
            localStorage.removeItem('uid');
            localStorage.removeItem('contingency');
           

        }).catch((error)=>{
            console.log(error)
        });
    }
    myChangeHandler = (event) => {
        event.preventDefault();
        localStorage.setItem('oksubmit', 'oksubmit');
        // window.location.reload(false);
    };
    // componentWillUpdate() {
    //     if (this.Data1 != null && this.Selfie != null) {
    //         this.setState({ isComplete: true });
    //     }
    // }


     sendsms(){
        this.pushAgentDetails()

        //let txt = `Dear Customer, Congratulations! Welcome to the family of CHILI. Your reference for eKYC Corelation ID is- ${this.state.token} for ${this.state.customernumber} Enjoy the CHiLi services.`
        let txt = `Congratulations your Re-Verification request is accepted and is subject to confirmation of documents submitted. Your Token ID is ${this.state.token}`;
        if(this.state.newCustomer=="yes"){
            this.state.customernumber=localStorage.getItem("AgentNumber")
            txt = `Congratulations your SIM request is accepted and is subject to confirmation of documents submitted. Your Token ID is ${this.state.token}`
        }
        // const req =  Axios.get("https://ekyc.chili.mu:9443/otp/verifyOtp/"+"230"+data.msisdn+"/"+data.otp,
        // let text = 'Dear Customer, Congratulations! Welcome to the family of CHILI. Your reference for eKYC is'+token+' Enjoy the CHiLi services.\n'+'Best regards\n'+'MTML Chilli';
        // const req = Axios.get("https://ekyc.chili.mu:9443/ekyc/v1/sms?msisdn="+"23058935671"+"&from=2306668&text="+txt+"&configId=2&locale=en",

        const req = Axios.get("https://ekyc.chili.mu:9443/ekyc/v1/sms?msisdn="+"230"+this.state.customernumber+"&from=2306668&text="+txt+"&configId=2&locale=en",
            {
            headers: {'Content-Type':'application/json; charset=utf-8'}
        }).then((res) => {
            console.log("result is ",res.data.status)
           
        
           }).catch((error)=>{
                        console.log(error);
                    });  
                    console.log("Token===>",txt)
    }

     sendEmail(){
        const req = Axios.get("https://ekyc.chili.mu:9443/email?email="+this.state.mailid+"&token="+this.state.token+"&status=success&newuser="+this.state.newCustomer,
        // const req = Axios.get("https://ekyc.chili.mu:9443/email?email=gagandeep@wpitservices.com&token=56445667asdafssfddfg&status=success",

        {
            headers:{'Content-Type':'application/json;charset=utf-8'}
        }).then((res)=>{
            console.log("email response is= ",res);
        }).catch((error)=>{
            console.log(error)
        });
    }

    
    render() {
        // console.log(this.state.isComplete);
        // if (!this.state.isComplete) {
        //     return (
        //         <div className="Auth-container">
        //             {alert('Details are pening\nPlease fill all the details ')}
                    // <Redirect to="/validateadd"></Redirect>;
        //         </div>
        //     );
        // }
        //  else {
           
        // }
        return (
        //     <div className="Auth-container" style={{ textAlign: 'left' }}>
        //         {
        //             this.state.agentNumber==null || this.state.agentNumber.trim().length==0?
        //             null
        //             :<div  style={{marginTop:-50,textAlign:'center'}}>
        //                 <label><strong>Agent Number:{this.state.agentNumber}</strong></label>
        //                 <Link to="/logout" style={{textAlign:'end',marginLeft:20,color:'black'}}>Logout</Link>
        //                 <br></br>
        //             </div>
        //         }
        //         <div style={{ marginTop: 0, marginLeft: 20 }}>
        //             <h4 style={{ color: '#00BFFF' }}>Confirmation Page</h4>
        //             <div style={{ fontWeight: 'Bold', color: 'Gray' }}>
        //             {/* {
        //                 this.state.agentNumber==null ||  this.state.agentNumber.trim().length==0 ?
        //                 <div>Please Confirm Your Details</div>
        //                 :<div>Please Confirm Customer Details</div>

        //             } */}
        //                 <div>Please Confirm Customer Details</div>
        //             </div>
        //             {/* <div style={{ fontSize: 13, color: 'Gray' }}>
        //                 Navigation is enabled for editing purposes
        //             </div> */}
        //         </div>
        //         {/* <Example data={this.state.Selfie} /> */}
        //         <div style={{marginLeft: 20}}>
        //             <h5
        //                 style={{
        //                     color: '#00BFFF',
        //                     textDecoration: 'underline',
        //                 }}
        //             >
        //                  {/* {
        //                 this.state.agentNumber==null ||  this.state.agentNumber.trim().length==0?
        //                 <div>Your KYC Details</div>
        //                 :<div> Customer KYC Details</div>

        //             } */}
        //             <div> Customer KYC Details</div>
        //             </h5>
        //         </div>
        //         <div style={{ marginTop: -20 }}>
        //             <List>
        //                 <ListItem>
        //                     <ListItemText
        //                         primary={
        //                             <div
        //                                 style={{
        //                                     color: '#00BFFF',
        //                                     textAlign: 'left',
        //                                 }}
        //                             >
        //                                 Full Name
        //                             </div>
        //                         }
        //                         secondary={
        //                             <div
        //                                 style={{
        //                                     color: 'gray',
        //                                     textAlign: 'left',
        //                                     fontSize: 18,
        //                                 }}
        //                             >                                 
        //                                 {localStorage.getItem('fn')+" "+localStorage.getItem('ln')}
        //                             </div>
        //                         }
        //                     />
        //                 </ListItem>
        //                 <ListItem>
        //                     <ListItemText
        //                         primary={
        //                             <div
        //                                 style={{
        //                                     color: '#00BFFF',
        //                                     textAlign: 'left',
        //                                 }}
        //                             >
        //                                 Gender
        //                             </div>
        //                         }
        //                         secondary={
        //                             <div
        //                                 style={{
        //                                     color: 'gray',
        //                                     textAlign: 'left',
        //                                     fontSize: 18,
        //                                 }}
        //                             >
                                       
        //                                 {localStorage.getItem("gender")}
        //                             </div>
        //                         }
        //                     />
        //                 </ListItem>
        //                 <ListItem>
        //                     <ListItemText
        //                         primary={
        //                             <div
        //                                 style={{
        //                                     color: '#00BFFF',
        //                                     textAlign: 'left',
        //                                 }}
        //                             >
        //                                 Date of Birth and Birth place
        //                             </div>
        //                         }
        //                         secondary={
        //                             <div
        //                                 style={{
        //                                     color: 'gray',
        //                                     textAlign: 'left',
        //                                     fontSize: 18,
        //                                 }}
        //                             >
                                        
        //                                     {localStorage.getItem('dob')+","+localStorage.getItem('birthplace')}
        //                             </div>
        //                         }
        //                     />
        //                 </ListItem>
                        
        //                 <ListItem>
        //                     <ListItemText
        //                         primary={
        //                             <div
        //                                 style={{
        //                                     color: '#00BFFF',
        //                                     textAlign: 'left',
        //                                 }}
        //                             >
        //                                 Document Type & Number
        //                             </div>
        //                         }
        //                         secondary={
        //                             <div
        //                                 style={{
        //                                     color: 'gray',
        //                                     textAlign: 'left',
        //                                     fontSize: 18,
        //                                 }}
        //                             >
                                        
        //                                     {localStorage.getItem('IDtype').toLocaleUpperCase()+" : "+localStorage.getItem('id')}
        //                             </div>
        //                         }
        //                     />
        //                 </ListItem>
        //                 <ListItem>
        //                     <ListItemText
        //                         primary={
        //                             <div
        //                                 style={{
        //                                     color: '#00BFFF',
        //                                     textAlign: 'left',
        //                                 }}
        //                             >
        //                                 Nationality
        //                             </div>
        //                         }
        //                         secondary={
        //                             <div
        //                                 style={{
        //                                     color: 'gray',
        //                                     textAlign: 'left',
        //                                     fontSize: 18,
        //                                 }}
        //                             >
                                        
        //                                     {localStorage.getItem('countryCode')}
        //                             </div>
        //                         }
        //                     />
        //                 </ListItem>
        //                 <ListItem>
        //                     <ListItemText
        //                         primary={
        //                             <div
        //                                 style={{
        //                                     color: '#00BFFF',
        //                                     textAlign: 'left',
        //                                 }}
        //                             >
        //                                 Marital Status
        //                             </div>
        //                         }
        //                         secondary={
        //                             <div
        //                                 style={{
        //                                     color: 'gray',
        //                                     textAlign: 'left',
        //                                     fontSize: 18,
        //                                 }}
        //                             >
                                        
        //                                     {localStorage.getItem('marital_status')}
        //                             </div>
        //                         }
        //                     />
        //                 </ListItem>
        //                 <ListItem>
        //                     <ListItemText
        //                         primary={
        //                             <div
        //                                 style={{
        //                                     color: '#00BFFF',
        //                                     textAlign: 'left',
        //                                 }}
        //                             >
        //                                 Profession
        //                             </div>
        //                         }
        //                         secondary={
        //                             <div
        //                                 style={{
        //                                     color: 'gray',
        //                                     textAlign: 'left',
        //                                     fontSize: 18,
        //                                 }}
        //                             >
                                        
        //                                     {localStorage.getItem('profession')}
        //                             </div>
        //                         }
        //                     />
        //                 </ListItem>
        //                 <ListItem>
        //                     <ListItemText
        //                         primary={
        //                             <div
        //                                 style={{
        //                                     color: '#00BFFF',
        //                                     textAlign: 'left',
        //                                 }}
        //                             >
        //                                 Contact Details
        //                             </div>
        //                         }
        //                         secondary={
        //                             <div
        //                                 style={{
        //                                     color: 'gray',
        //                                     textAlign: 'left',
        //                                     fontSize: 18,
        //                                 }}
        //                             >
                                        
        //                                     {localStorage.getItem('address')}<br></br>
        //                                     {localStorage.getItem('locality')}<br></br>
        //                                     { localStorage.getItem('contactNumber')==0 ?
        //                                       null
        //                                     : <div>
        //                                         {localStorage.getItem('contactNumber')}<br></br>
        //                                     </div>
                                            
        //                                     }
        //                                     {localStorage.getItem('email')}
        //                             </div>
        //                         }
        //                     />
        //                 </ListItem>
        //                 <ListItem>
        //                     <ListItemText
        //                         primary={
        //                             <div
        //                                 style={{
        //                                     color: '#00BFFF',
        //                                     textAlign: 'left',
        //                                 }}
        //                             >
        //                                 <h5
        //                                 style={{
        //                                     color: '#00BFFF',
        //                                     textDecoration: 'underline'
        //                                     , marginLeft: 20
        //                                 }}>Bank Related Details</h5>
        //                             </div>
        //                         }
        //                         secondary={
        //                             <div
        //                                 style={{
        //                                     color: 'gray',
        //                                     textAlign: 'left',
        //                                     fontSize: 18,
        //                                 }}
        //                             >
                                        
        //                                     <label><strong>Bank Name:</strong></label>{localStorage.getItem('bankName')}<br></br>
        //                                     <label><strong>Account Type:</strong></label>{localStorage.getItem('accountType')}<br></br>
        //                                     <label><strong>Joint Account:</strong></label>{localStorage.getItem('joint_a/c')}
        //                             </div>
        //                         }
        //                     />
        //                 </ListItem>
                        
        //             </List>
        //         </div>

        //         <div>
        //             <h5
        //                 style={{
        //                     color: '#00BFFF',
        //                     textDecoration: 'underline'
        //                     , marginLeft: 20
        //                 }}
        //             >
        //                 Profile Photo
        //             </h5>
        //         </div>
        //         <div>
        //         <img
        //             className="selpic_successpage"
        //             src={`data:image/jpeg;base64,${localStorage.getItem('imgData')}`}
        //             alt="selpic_successpage"
        //             style={{ marginLeft: -120, marginTop: 0, marginLeft: 20 }}
        //         />
        //         </div>
                
        //         <br></br>
        //         <br></br>
        //         <div style={{ marginTop: 220 }}>
        //             <h5
        //                 style={{
        //                     color: '#00BFFF',
        //                     textDecoration: 'underline', marginLeft: 20
        //                 }}
        //             >
        //                 Identity Card
        //             </h5>
        //         </div>
        //         {/* <div className="header"> */}
                
        //         <div>
        //         <img
        //             className="classid_f_succ"
        //             src={`data:image/jpeg;base64,${localStorage.getItem('imgId1')}`}
        //             alt="classid_f_succ"
        //             height="160"
        //             width="260"
        //             style={{ marginTop: 0 }}
        //         />
        //         </div>
                
        //         <div style={{ marginTop: 160 }}>
        //             <div style={{ color: '#00BFFF', padding: 15 }}>
        //             <h5
        //                             style={{
        //                                     color: '#00BFFF',
        //                                     textDecoration: 'underline'
        //                                      }}
        //                         >
        //                              Address Proof 
        //                         </h5>
                        
        //             </div>
        //         </div>
                        
        //             <img
        //             className="classid_b_succ"
        //             src={`data:image/jpeg;base64,${localStorage.getItem('imgId7')}`}
        //             alt="classid_b_succ"
        //             height="160"
        //             width="260"
        //             style={{ marginTop: 0}}
        //             />
        //             <br></br>
        //             <br></br>
                   
                    

        //             {this.state.addressprooftype == "Consent"?
                    
        //             <div>
        //             <br></br>
        //             <br></br>
        //                 <img
        //             className="classid_b_succ"
        //             src={`data:image/jpeg;base64,${localStorage.getItem('imgId10')}`}
        //             alt="classid_b_succ"
        //             height="160"
        //             width="260"
        //             style={{ marginTop: 0}}
        //             />
        //             <br></br>
        //             <br></br>
        //             </div>
        //         :null}
                    
                    
 
                    
        //             {
        //                 this.state.UserType == "resident" || this.state.UserType == "tourist" ?
                    
        //         <div>
                   
        //            <br></br>
        //            <br></br>
        //            <br></br>
        //            <br></br>
        //             <br></br>
        //             <br></br>
        //                     <img
        //             className="classid_b_succ"
        //             src={`data:image/jpeg;base64,${localStorage.getItem('imgId8')}`}
        //             alt="classid_xxxx_succ"
        //             height="160"
        //             width="260"
        //             style={{   marginTop: 0 }}
        //             />
        //                 </div>
                    
        //             :null
        //             } 
        //         <div>
                    
        //         </div>
        //         <br></br>
        // <br></br>
        // <br></br>
        // <br></br>
        // <br></br>
        // <br></br>
        //             <hr></hr>
        
        // <div style={{textAlign:'center'}}>
        //     <p>I declare that the information provided above are correct and I agree for the terms and conditions.</p>
        //         <input value="checked" type="checkbox" checked disabled /><span> I Agree </span>
        //                 <Link to="/terms">Terms&Conditions </Link><br></br>
        //             </div>
        //         <div style={{textAlign:'center', marginTop:30}}>

        //             {/* { 
        //             this.state.agentNumber==null? */}
        //             <div>
        //             <button
        //             className="button_id"
        //             onClick={this.myChangeHandler}
        //             style={{
        //                 fontSize: 16,
        //                 width:150,
        //             }}
        //         >
        //              <Link className="link1" to="/conclude"
        //             //  onClick={()=>{this.sendEmail();this.sendsms()}}
        //              >
        //                 Submit
        //             </Link>  
        //             </button>
        //             </div>
        //             {/* :<button
        //             className="button_id"
        //             onClick={this.myChangeHandler}
        //             style={{
        //                 fontSize: 16,
        //                 width:150,
                        
        //             }}
        //         >
        //             <Link className="link1" to="/customer">
        //                 Save and Continue
        //             </Link>
                 
                    

        //         </button> */}
        //         {/* } */}
                    
                
        //         </div>
        //     </div>
        <div className="Auth-container" style={{ marginTop:-30 }}>
            <div className='row mx-2 text-center'>
                <div className='col-12'>
                {
                localStorage.getItem('bankName')=='AFG Holding' ?
                    <div className='row text-center '>
                        <div className='col-sm-12'>
                            <img src={afgbank} style={{ height: 80, width:120, marginTop:-30 }} alt="logo"></img>
                        </div>
                        <div className='col-sm-12'>
                            <h6>AFG Holding</h6>
                        </div> 
                    </div>
                :localStorage.getItem('bankName')=='Mansa Bank' ?
                <div className='row'>
                    <div className='col-sm-12'>
                        <img src={mansa} style={{ height: 80, width:120, marginTop:-30 }} alt="logo"></img>
                    </div>
                    <div className='col-sm-12'>
                            <h6>MANSA BANK</h6>
                    </div>
                </div>
                :localStorage.getItem('bankName')=='BICICI Bank' ?
                <div className='row'>
                    <div className='col-sm-12'>
                        <img src={bicici} style={{ height: 80, width:120, marginTop:-30 }} alt="logo"></img>
                    </div>
                    <div className='col-sm-12'>
                            <h6>BICICI Bank</h6>
                    </div>
                </div>
                :null
            }
                </div>
            </div>
            

            <div className='row ' style={{marginTop:-50}}>

                <div className='col-12 col-md-7 mt-5 '>
                {
                localStorage.getItem('accountType')=='Savings Account' ?
                <div>
                    <div className='col-12 '>
                        <h6>Account Type</h6>

                        <Checkbox {...label} disabled checked /> <label>Current Account</label>
                    </div>
                    <div className='col-12'>
                        <Checkbox {...label} disabled /> <label>Current Account</label>
                    </div>
                </div>
                :<div>
                    <div className='col-12'>
                        <h6>Account Type</h6>
                        <Checkbox {...label} disabled  /> <label>Savings Account</label>
                    </div>
                    <div className='col-12 '>
                        <Checkbox {...label} disabled checked /> <label>Current Account</label>
                    </div>
                </div>
                }
                </div>

                
                <div className='col-12 col-md-4'>
                <img
                    className="selpic_successpage" id='selfiepic'
                    src={`data:image/jpeg;base64,${localStorage.getItem('imgData')}`}
                    alt="selpic_successpage"
                    
                />
                </div>
                
                

                
            </div>
            
            <div className='row mt-2 mx-2' >
                <div className='col-12'>
                        <h6 style={{ backgroundColor: 'gray', color: 'white' }}>PERSONAL DETAILS</h6>
                </div>
            </div>
            <div className='row mx-2'>
                <div className='col-12 col-md-5'>
                <TextField
                           fullWidth
                            style={{marginBottom:5}}
                            id="outlined-required"
                            label={<div style={{color:'black'}}>Full Name</div>}
                            variant="outlined"
                            value={localStorage.getItem('fn')+" "+localStorage.getItem('ln')}/>
                </div>
                <div className='col-12 col-md-7'>
                <TextField
                            
                            fullWidth
                            style={{marginBottom:5,}}
                            id="outlined-required"
                            label={<div style={{color:'black'}}>DOB & Place</div>}
                            variant="outlined"
                            value={localStorage.getItem('dob')+","+localStorage.getItem('birthplace')}/>
                </div>
                
            </div>
            <div className='row mx-2'>
                <div className='col-12'>
                <TextField
                            
                            fullWidth
                            style={{marginBottom:5}}
                            id="outlined-required"
                            label={<div style={{color:'black'}}>Address</div>}
                            variant="outlined"
                            value={localStorage.getItem('address')}/>
                </div>
            </div>
            <div className='row mx-2'>
                <div className='col-12 col-md-4'>
                <TextField      
                            fullWidth                      
                            style={{marginBottom:5}}
                            id="outlined-required"
                            label={<div style={{color:'black'}}>Nationality</div>}
                            variant="outlined"
                            value={localStorage.getItem('countryCode')}/>
                </div>
                <div className='col-12 col-md-4'>
                <TextField   
                            fullWidth                         
                            style={{marginBottom:5}}
                            id="outlined-required"
                            label={<div style={{color:'black'}}>Profession</div>}
                            variant="outlined"
                            value={localStorage.getItem('profession')}/>
                </div>
                <div className='col-12 col-md-4'>
                <TextField 
                            fullWidth                           
                            style={{marginBottom:5}}
                            id="outlined-required"
                            label={<div style={{color:'black'}}>Maiden Name</div>}
                            variant="outlined"
                            />
                </div>
            </div>
            <div className='row mx-2'>
                <div className='col-12'>
                <TextField  fullWidth                  
                            style={{marginBottom:5}}
                            id="outlined-required"
                            label={<div style={{color:'black'}}>Employer Name & Address</div>}
                            variant="outlined"
                            value={" "}/>
                </div>
                
            </div>
            <div className='row mx-2'>
                <div className='col-12 col-md-6'>
                <TextField  fullWidth                          
                            style={{marginBottom:5}}
                            id="outlined-required"
                            label={<div style={{color:'black'}}>contactNumber</div>}
                            variant="outlined"
                            value={localStorage.getItem('contactNumber')}/>
                </div>
                <div className='col-12 col-md-6'>
                <TextField  fullWidth                         
                            style={{marginBottom:5}}
                            id="outlined-required"
                            label={<div style={{color:'black'}}>Email</div>}
                            variant="outlined"
                            value={localStorage.getItem('email')}/>
                </div>
            </div>
            <div className='row'>
                <div className='col-12 col-md-4'>
                    <h6>Gender</h6>
                {
                localStorage.getItem('gender')=='Male' ?
                <div>
                    <div className='col-12'>
                        <Checkbox {...label} disabled checked /> <label>Male</label>
                    </div>
                    <div className='col-12'>
                        <Checkbox {...label} disabled /> <label>Female</label>
                    </div>
                </div>
                :<div>
                    <div className='col-12'>
                        <Checkbox {...label} disabled  /> <label>Male</label>
                    </div>
                    <div className='col-12'>
                        <Checkbox {...label} disabled checked /> <label>Female</label>
                    </div>
                </div>
                }
                </div>
                <div className='col-12 col-md-4'>
                    <h6>Marital Status</h6>
                {
                localStorage.getItem('marital_status')=='married' ?
                <div>
                    <div className='col-12'>
                        <Checkbox {...label} disabled checked /> <label>Married</label>
                    </div>
                    <div className='col-12'>
                        <Checkbox {...label} disabled /> <label>Single</label>
                    </div>
                    <div className='col-12'>
                        <Checkbox {...label} disabled /> <label>Other</label>
                    </div> 
                </div>
                :localStorage.getItem('marital_status')=='unmarried' ?
                <div>
                    <div className='col-12'>
                        <Checkbox {...label} disabled  /> <label>Married</label>
                    </div>
                    <div className='col-12'>
                        <Checkbox {...label} disabled checked /> <label>Single</label>
                    </div>
                    <div className='col-12'>
                        <Checkbox {...label} disabled /> <label>Other</label>
                    </div> 
                </div>
                :<div>
                <div className='col-12'>
                    <Checkbox {...label} disabled  /> <label>Married</label>
                </div>
                <div className='col-12'>
                    <Checkbox {...label} disabled  /> <label>Single</label>
                </div>
                <div className='col-12'>
                        <Checkbox {...label} disabled checked/> <label>Other</label>
                    </div> 
            </div>
                }
                </div>
                <div className='col-12 col-md-4'>
                    <h6>Document Type</h6>
                {
                localStorage.getItem('IDtype')=='nid' ?
                <div>
                    <div className='col-12'>
                        <Checkbox {...label} disabled checked /> <label>National ID(CNI)</label>
                    </div>
                    <div className='col-12'>
                        <Checkbox {...label} disabled /> <label>Passport</label>
                    </div>
                    <div className='col-12'>
                        <Checkbox {...label} disabled /> <label>Driving Licence</label>
                    </div> 
                </div>
                :localStorage.getItem('IDtype')=='Passport' ?
                <div>
                    <div className='col-12'>
                        <Checkbox {...label} disabled  /> <label>National ID(CNI)</label>
                    </div>
                    <div className='col-12'>
                        <Checkbox {...label} disabled checked /> <label>Passport</label>
                    </div>
                    <div className='col-12'>
                        <Checkbox {...label} disabled /> <label>Driving Licence</label>
                    </div> 
                </div>
                :<div>
                <div className='col-12'>
                    <Checkbox {...label} disabled  /> <label>National ID(CNI)</label>
                </div>
                <div className='col-12'>
                    <Checkbox {...label} disabled  /> <label>Passport</label>
                </div>
                <div className='col-12'>
                        <Checkbox {...label} disabled checked/> <label>Driving Licence</label>
                    </div> 
            </div>
                }
                </div>
            </div>
            
            
            <div className='row mt-2'>
                <div className='col-12 col-md-6'>
                    <h6>Identity Card</h6>
                <img
                    className="classid_f_succ"
                    src={`data:image/jpeg;base64,${localStorage.getItem('imgId1')}`}
                    alt="classid_f_succ"
                    height="160"
                    width="260"
                    style={{ marginTop: 0 }}
                />
                </div>
                <div className='col-12 col-md-6'>
                    <h6>Address Proof</h6>
                    <img
                    className="classid_b_succ"
                    src={`data:image/jpeg;base64,${localStorage.getItem('imgId7')}`}
                    alt="classid_b_succ"
                    height="160"
                    width="260"
                    style={{ marginTop: 0}}
                    />
                </div>
            </div>
            <div className='row text-center mx-2'>
                <div className='col-12 '>
                <p>I declare that the information provided above are correct and I agree for the terms and conditions.</p>
                <input value="checked" type="checkbox" checked disabled /><span> I Agree </span>
                        <Link>Terms & Conditions </Link><br></br>
                </div>
                <div className='col-12 mt-2'>
                <button
                    className="button_id"
                    onClick={this.myChangeHandler}
                    style={{
                        fontSize: 16,
                        width:150,
                    }}
                >
                     <Link className="link1" to="/conclude">Submit</Link>  
                    </button>
                </div>
            </div>
        </div>
        );
    }
}

export default Success;